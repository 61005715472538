import React from 'react'
import {forgotPasswordSchema} from '../../validation/authSchema';
import { FormInput, Form } from 'components/form';
import { Button } from 'components/buttons';
import {useNavigate} from 'react-router-dom';


interface ForgotPasswordFormProps {

}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = () => {
    const navigate = useNavigate();

    return <>
        <Form
            validationSchema={forgotPasswordSchema}
            initialValues={{
                email: ''
            }}
            onSubmit={values => console.log(values)}
        >
            <FormInput
                name='email'
                label='Provide Email'
                placeholder='Email'
                type='email'
            />
            <Button type='submit' color='primary' block>Submit</Button>  
        </Form>
        <Button color='ghost' size='xs' onClick={()=> navigate(-1)}>Back</Button>
    </>;
}

export default ForgotPasswordForm