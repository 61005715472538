import React from 'react';
import {
    Formik,
    Form as FForm,
    
} from 'formik';
import {Schema} from 'yup';

interface FormProps {
    validationSchema?:Schema | (() => Schema);
    initialValues: any;
    onSubmit: (result:any) => void;
    children: React.ReactNode
}   

const Form: React.FC<FormProps> = ({children,...props}) => {
    return <Formik
        initialValues={props.initialValues}
        validationSchema={props.validationSchema}
        onSubmit={props.onSubmit}
    >
        <FForm>
            {children}
        </FForm>
    </Formik>
}

export default Form