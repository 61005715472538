import * as yup from 'yup';

export const authSchema = yup.object().shape({
    email: yup.string()
        .email('Invalid Format')
        .required('Required'),
    password: yup.string()
        .required('Required')
})

export const forgotPasswordSchema = yup.object().shape({
    email: yup.string().email('Invalid format').required('Required')
})