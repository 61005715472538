import {createBrowserRouter} from "react-router-dom";
// import {Forms, Components, Inputs, Select, Tables, Skeletons} from "features/components";
import {Login,ForgotPassword} from 'features/authentication';
import App from '../App';
import { NotFoundPage } from "pages";
import { CorporateScoreCard, Logs } from "features/scheduler";

const route = createBrowserRouter([
    {
        path:'/',
        element: <App/>,
        children:[
            {
                path:'logs',
                element: <Logs/>
            },
            {
                path:'corporate-scorecard',
                element:<CorporateScoreCard/>
            }
            // {
            //     path:'buttons',
            //     element: <Components/>         
            // },
            // {
            //     path:'inputs',
            //     element: <Inputs/> 
            // },
            // {
            //     path: 'forms',
            //     element: <Forms/>
            // },
            // {
            //     path:'select',
            //     element: <Select/>
            // },
            // {
            //     path:'tables',
            //     element: <Tables/>
            // },
            // {
            //     path:'skeletons',
            //     element:<Skeletons/>
            // }
        ]    
    },
    {
        path:'/login',
        element: <Login/>
    },
    {
        path:'/forgot-password',
        element: <ForgotPassword/>
    },
    {
        path: '*',
        element: <NotFoundPage/>
    }
])

export default route