import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {isRejectedWithValue} from '@reduxjs/toolkit';
import type {MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import {RootState} from '../store';

import {toast} from 'react-toastify';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers,{ getState }) => {
        const token = (getState() as RootState).auth.token;
        
        if (token) {
            headers.set('x-access-token', token)

        }

        return headers
    }
})

export const apiSlice = createApi({
    reducerPath:'apiSlice',
    baseQuery: baseQuery,
    tagTypes: ['Pagination'],
    endpoints: builder => ({})
}) 

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if(isRejectedWithValue(action)){
        toast.error(action.payload.data.message)
    }

    return next(action)
} 

//handles error result from the server
