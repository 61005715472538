import React from 'react';
import {Dialog} from '@headlessui/react'

interface ModalBodyProps {
    subtitle?: string;
    children: React.ReactNode
}

const ModalBody: React.FC<ModalBodyProps> = (props) => {
    return <div className='p-5 flex flex-col gap-2'>
    <Dialog.Description>
        {props.subtitle}
    </Dialog.Description>
    {props.children}
</div>
}

export default ModalBody