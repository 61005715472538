import React from 'react'

interface CardActionProps {
    children: React.ReactNode
}

const CardAction: React.FC<CardActionProps> = (props) => {
    return <div className='card-actions'>
        {props.children}
    </div>;
}

export default CardAction