import React from 'react';
import DrawerContainer from './DrawerContainer';
import DrawerContent from './DrawerContent';
import DrawerFooter from './DrawerFooter';

interface DrawerProps {
  
}

const Drawer: React.FC<DrawerProps> = () => {
    return (
        <DrawerContainer>
            <DrawerContent/>
            <DrawerFooter>
            </DrawerFooter>
        </DrawerContainer>
    )
}

export default Drawer