import React from 'react';
import {
    PaginationState,
    Updater
} from '@tanstack/react-table';
import { Button } from 'components/buttons';
import {HiChevronLeft, HiChevronRight} from 'react-icons/hi';

interface PaginationProps {
    setPageIndex:       (updater: Updater<number>) => void; 
    getCanPreviousPage: () => boolean;
    nextPage:           ()=> void;
    previousPage:       ()=> void;
    getCanNextPage:     () => boolean;
    getPageCount:       () => number;
    getState:           PaginationState,
    setPageSize:        (updater: Updater<number>) => void;
    isLoading?:          boolean;
}

const Pagination: React.FC<PaginationProps> = (props) => {
    return <div className='flex h-10 py-2 px-2 items-center'>
        <div className='flex-1'>
            <label className = 'label'>
                <span className='label-text'>Page <strong>{Number(props.getState.pageIndex) + 1}</strong> to <strong>{isNaN(props.getPageCount()) ? 0 : props.getPageCount()}</strong></span>    
            </label> 
        </div>    
        <div className='flex gap-2 items-center'>
            <Button size='sm' outlined disabled={!props.getCanPreviousPage()} onClick={() => props.previousPage()} isLoading={props.isLoading} square><HiChevronLeft/></Button>
            <Button size='sm' outlined disabled={!props.getCanNextPage()}    onClick={() => props.nextPage()} isLoading={props.isLoading} square ><HiChevronRight/></Button>
        </div>
    </div>;
}

export default Pagination