import React from 'react'

interface CardsProps extends React.HTMLAttributes<HTMLDivElement>{
    children?: React.ReactNode;
    title?: string;

}

const Cards: React.FC<CardsProps> = (props) => {
    return <div className='card bg-base-100'>
       <div className='card-body '>
            <h2 className="card-title">{props.title}</h2>
            {props.children}
       </div>
    </div>;
}

export default Cards