import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';

type appSliceType = {
    drawer: boolean;
}

const initialState:appSliceType = {
    drawer: false
}

export const appSlice = createSlice({
    name:'app',
    initialState: initialState,
    reducers: {
        toggleDrawer:(state) => {
            state.drawer = !state.drawer
        }
    }
})

export const getDrawer = (state: RootState) => state.app.drawer
export const {toggleDrawer} = appSlice.actions;
export default appSlice.reducer