import React from 'react'
import {Menu as HMenu,Transition} from '@headlessui/react';

interface MenuProps {
    children: React.ReactNode;
    icon?: React.ReactNode;
    text?: string;
}

const Menu: React.FC<MenuProps> = (props) => {
    return <HMenu as='div' className="relative inline-block text-left">
        <HMenu.Button>
                <div>
                    {props.text}{props.icon}
                </div>
        </HMenu.Button>
        <Transition 
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <HMenu.Items className="absolute right-0 mt-2 w-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {props.children}
            </HMenu.Items>
        </Transition>
    </HMenu>
}

export default Menu