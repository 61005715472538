import React from 'react';
import Menu from '../../menu/Menu';
import MenuItem from '../../menu/MenuItem';

import {FaUserCircle} from 'react-icons/fa';

import { Card, CardAction } from 'components/layouts';
import { Button } from 'components/buttons';
import { useNavigate } from 'react-router-dom';
import Modal  from 'components/modals';
import { useDisclosure, useAppSelector, useAppDispatch } from 'hooks';
import {getAccessToken,logout} from 'lib/redux/slices/auth.slice';
import {useLogutMutation} from 'lib/redux/api/auth.slice';
import jwt_decode from 'jwt-decode';
interface AccountMenuProps {
    
}

const AccountMenu: React.FC<AccountMenuProps> = (props) => {
    const dispatch = useAppDispatch();
    const [logoutApi,{isLoading}] =  useLogutMutation();
    const token = useAppSelector(getAccessToken);
    const decodedToken = jwt_decode<{email:string}>(token || '');
    const navigate = useNavigate();
    const {isOpen,onOpen,onClose} = useDisclosure();

    const handleSignOut = async () => {
        await logoutApi({})
        .unwrap()
        .then(result => {
            dispatch(logout())
            navigate('/login',{
                replace:true
            })
        })
    }


    return (
        <div>
            <Menu 
                icon={<FaUserCircle size={'25px'} className="text-slate-400"/>}
            >   
                <MenuItem>
                    <Card title='Hello !'>
                        <p className=' font-semibold'>{decodedToken?.email}</p>
                        <p>Administrator</p>
                        <div className='divider'/>
                        <CardAction>
                            <Button size='sm' color='primary' block onClick={onOpen}>Update Account</Button>
                            <Button size='sm' color='primary' block onClick={handleSignOut} isLoading={isLoading}>Sign Out</Button>
                        </CardAction>
                    </Card>
                </MenuItem>
            </Menu>
            <Modal title='Update Account' isOpen={isOpen} onClose={onClose}/>
        </div>
    )
}

export default AccountMenu