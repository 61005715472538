import React from 'react';
import {Disclosure, Transition} from '@headlessui/react';
import {BiChevronUp} from 'react-icons/bi';
import { NavLink, useLocation } from 'react-router-dom';
import {useAppDispatch} from 'hooks';
import {toggleDrawer} from 'lib/redux/slices/app.slice';
interface DrawerItemsProps {
    header:string;
    childrens: {
        pathName: string;
        path: string
    }[]
}

const DrawerItems: React.FC<DrawerItemsProps> = (props) => {
    const [isOpen, setOpen] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();
    const location = useLocation();

    const toggle = () => {
        setOpen(!isOpen)
    }
    React.useEffect(() => {
        setOpen(props.childrens.find(item => item.path === location.pathname) ? true : false)  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return <>
            <Disclosure as='li' className='select-none cursor-pointer font-semibold'>
                {
                    ({open}) => (
                        <>
                            <Disclosure.Button className={`hover:bg-orange-600 hover:text-white sm:px-6 py-3 w-full h-fit flex justify-between ${open ? 'shadow-lg' : ''}`} onClick={toggle}>
                                <span>{props.header}</span>
                                <BiChevronUp
                                className={`${
                                    open ? 'transition duration-75 rotate-180 transform' : 'transition duration-75'
                                } h-5 w-5`}
                                />
                            </Disclosure.Button>
                            <Transition
                                show={isOpen}
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <Disclosure.Panel static className={'bg-[#1A1A1B] drop-shadow-lg'}>
                                    <ul>
                                        {
                                            props.childrens.map(child => (
                                                <li key={child.path}>
                                                    <NavLink to={child.path} onClick={()=>dispatch(toggleDrawer())} >
                                                        {
                                                            ({isActive}) => (
                                                                <div className={`${isActive ? 'bg-orange-800 text-white' : ''} sm:pl-10 py-3 w-full hover:bg-orange-800 hover:text-white`}>
                                                                    {child.pathName}
                                                                </div>
                                                            )
                                                        }
                                                        
                                                    </NavLink>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </Disclosure.Panel> 
                            </Transition>
                        </>
                    )
                }
            </Disclosure>
    </>;
}

export default DrawerItems