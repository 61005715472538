import React from 'react';
import {authSchema} from '../../validation/authSchema';
import { FormInput, Form } from 'components/form';
import { Button } from 'components/buttons';
import {useNavigate} from 'react-router-dom';
import {useLoginMutation} from 'lib/redux/api/auth.slice';
import { useAppDispatch } from 'hooks';
import {login} from 'lib/redux/slices/auth.slice';
interface LoginFormProps {

}

const initialValues = {
    email: '',
    password: ''
}

const LoginForm: React.FC<LoginFormProps> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loginApi, {isLoading}] = useLoginMutation();
 

    return <>
        <Form 
            validationSchema={authSchema}
            initialValues={initialValues}
            onSubmit={async(values) => {
                await loginApi({
                    body:{
                        email: values.email,
                        password: values.password
                    }
                })
                .unwrap()
                .then(result => {
                    dispatch(login({
                        token: result.token,
                        access: []
                    }))

                    navigate('/')
                })
            }}
        >
            <FormInput

                name='email'
                label='Email'
                placeholder='Email'
                type='email'
            />
            
            <FormInput
                name='password'
                label='Password'
                placeholder='Password'
                type='password'
            />

            <Button className=' bg-[#ED6D01] border-0 hover:bg-[#9E4800] font-bold' type='submit' color='primary' isLoading={isLoading} block>Sign In</Button>  
        </Form>
        <div className='flex justify-end mt-2'>
            <Button color='ghost' size='xs' onClick={() => navigate('/forgot-password')}> Forgot Password</Button>
        </div>
    </>;
}

export default LoginForm