import React from 'react';
import {sizes} from 'components/types';
import {
    Field,
    FieldProps
} from 'formik';

interface FormInputProps  {
    type?: string;
    label?:string;
    placeholder?: string;
    name: string;
    size?: sizes;
    disabled?: boolean;
    className?: string;
}

const sizeList = {
    normal: '',
    xs: 'input-xs',
    sm: 'input-sm',
    md: 'input-md',
    lg: 'input-lg',  
}   

const FormInput: React.FC<FormInputProps> = ({className,label,size='normal',placeholder,disabled,type='text',...props}) => {
    return (
        <Field {...props}>
            {
                ({
                    field,
                    form:{touched,errors}
                }:FieldProps<any>)=> (
                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">{label}</span>
                        </label>
                        <input className={`input input-bordered ${sizeList[size]}`} {...field} disabled={disabled} placeholder={placeholder} type={type}/>
                        <label className="label">
                            <span className={`${className} label-text-alt text-red-500 ${errors[field.name] || touched? 'visible' : 'invisible'}`}><>{errors[field.name]}</></span>
                        </label>
                    </div>
                )
            }
        </Field>
    )
}

export default FormInput