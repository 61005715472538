import React from 'react'

interface ContentProps {
    children?: React.ReactNode
}

const Content: React.FC<ContentProps> = (props) => {
    return <div className='relative top-20 mx-10 mb-10 flex flex-col gap-2'>
        {props.children}
    </div>;
}

export default Content