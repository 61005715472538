import React from 'react'

interface ModalActionsProps {
    children: React.ReactNode
}

const ModalActions: React.FC<ModalActionsProps> = ({children}) => {
    return<div className='flex gap-2 px-6 py-4 border-t justify-end'>
          {children}                          
    </div>
}

export default ModalActions