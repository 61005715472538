import React from 'react'
import Modal from 'components/modals'

interface ManualModalProps {
    data?: any;
    isOpen: boolean;
    onClose: () => void;
}

const ManualModal: React.FC<ManualModalProps> = (props) => {
    return <Modal isOpen={props.isOpen} onClose={props.onClose}>
        
    </Modal>
}

export default ManualModal