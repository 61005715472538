import React from 'react';
import {Container, Header} from 'components/layouts';
import SchedulerTable from '../components/tables/SchedulerTable';

interface LogsProps {

}

const Logs: React.FC<LogsProps> = () => {
    return <>
        <Header title='Scheduler Logs'></Header>
        <Container>
            <SchedulerTable/>
        </Container>
    </>;
}

export default Logs