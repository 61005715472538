import React from 'react'
import {
    useReactTable,
	flexRender,
    ColumnDef,
    getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
    PaginationState
} from '@tanstack/react-table';
import {BiRefresh} from 'react-icons/bi'
import Pagination from './Pagination';
import {
    useGetPaginatedQuery
} from 'lib/redux/api/table.api.slice';
import { Button } from 'components/buttons';

interface APITableProps<T extends Object>{
    columns: ColumnDef<T>[],
    title?: string,
    route: string
}

const APITable = <T extends Object>({title,route,columns}: APITableProps<T>)  => {   
    const [{pageIndex,pageSize},setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10
    })

	// const [isPageChanged, setPageChange] = React.useState(false);
	
	const {data = {}, isFetching,refetch} = useGetPaginatedQuery({
        route: route,
		page:  pageIndex,
		limit: pageSize
    })

	

    const table = useReactTable({
		data:data?.data || [],
		pageCount: Math.ceil(data?.total / pageSize)  ?? -1,
		columns,
		state:{
			pagination:{
                pageIndex,
                pageSize
            }
            //sorting
		},
		//onSortingChange: setSorting,
        onPaginationChange:     setPagination,
		getCoreRowModel:        getCoreRowModel(),
		getPaginationRowModel:  getPaginationRowModel(),
		getSortedRowModel:      getSortedRowModel(),
		manualPagination: true
	})

    return <div className='border rounded-lg'>
        <div className='flex items-center p-1'>
			<div className='flex-1'>
				<Button size='sm' color='ghost' onClick={refetch} isLoading={isFetching} square><BiRefresh/></Button>
			</div>
			<label className='label text-sm'>
				<span>Total Result: <strong>{data?.total}</strong></span>
			</label>
		</div>
        <div className='relative overflow-x-auto max-h-[570px]'>
            <table className='w-full text-sm text-left'>
                <thead className='text-xs uppercase bg-base-200 sticky top-0'>
                {
						table.getHeaderGroups().map(headerGroup => (
							<tr key={headerGroup.id}>
								{
									headerGroup.headers.map(header => {
										return (
											<th key={header.id} colSpan={header.colSpan} className='px-6 py-3'>
												{
													header.isPlaceholder ? null : 
													(
														<div 
                                                        // {...{
														// 	className: `flex gap-1 items-center ${header.column.getCanSort() 
														// 	? 'cursor-pointer select-none' : ''}`,
														// 	onClick: header.column.getToggleSortingHandler(),	
														// }}
														>
															{flexRender(header.column.columnDef.header, header.getContext())}
															{/* {{
																asc: <HiChevronUp/>,
																desc: <HiChevronDown/>,
															}[header.column.getIsSorted() as string] ?? null} */}
														</div>
													)
												}
											</th>
										)
									})
								}
							</tr>
						))
					}
				</thead>
				<tbody>
					{
						table.getRowModel().rows.map(row => {
							return (
								<tr key={row.id} className='border-b bg-base-100'>
									{
										row.getVisibleCells().map(cell => {
											return (
												<td key={cell.id} className='px-6 py-4'>
													{
														flexRender(
															cell.column.columnDef.cell,
															cell.getContext()
														)
													}
												</td>
											)
										})
									}
								</tr>
							)
						})
					}
	
				</tbody>
            </table>
        </div>
        <Pagination
			setPageIndex={table.setPageIndex}      
			getCanPreviousPage={table.getCanPreviousPage}
			nextPage={table.nextPage}
			previousPage={table.previousPage}
			getCanNextPage={table.getCanNextPage}
			getPageCount={table.getPageCount}
			getState={table.getState().pagination}         
			setPageSize={table.setPageSize}   
			isLoading={isFetching}
		/>
    </div>
}

export default APITable