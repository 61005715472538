import React from 'react'
import {Container, Header} from 'components/layouts';
import ScoreCardTable from '../components/tables/ScoreCardTable';


interface corporate_scorecardProps {

}

const Corporate_scorecard: React.FC<corporate_scorecardProps> = () => {
    return <>
        <Header title='Corporate Scorecard'></Header>
        <Container>
            <ScoreCardTable/>
        </Container>    
    </>;
}

export default Corporate_scorecard