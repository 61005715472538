import {createContext} from 'react';

export type modalContextType = {
    start_time_label: Date | null;
    start_time_cron: string | null;
    is_active: boolean;
    scheduler_id: string | null;
}

export const ModalContext = createContext<modalContextType>({
    start_time_label: null,
    start_time_cron: null,
    is_active: false,
    scheduler_id: null
})