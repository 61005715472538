const modules : {
    header_id: string;
    header: string;
    childrens:{
        path:string;
        pathName: string
    }[]
} [] = [
    // {
    //     header_id: 'component',
    //     header:'Components',
    //     childrens: [
    //         {
    //             path:'/buttons',
    //             pathName:'Buttons'
    //         },
    //         {
    //             path:'/inputs',
    //             pathName:'Inputs'
    //         },
    //         {
    //             path:'/select',
    //             pathName:'Select'
    //         },
    //         {
    //             path:'/tables',
    //             pathName:'Tables'
    //         },
    //         {
    //             path:'/forms',
    //             pathName:'Forms'
    //         },
    //         {
    //             path:'/skeletons',
    //             pathName:'Skeleton'
    //         }
    //     ]
    // },
    {
        header_id: 'administration',
        header:'Administration',
        childrens:[
            {
                path:'/users',
                pathName:'User Management'
            },
            // {
            //     path:'/roles',
            //     pathName:'Role Management'
            // }
        ]
    },
    {
        header_id: 'scheduler',
        header:'Scheduler',
        childrens:[
            {
                path:'/logs',
                pathName:'Logs'
            },
            {
                path:'/corporate-scorecard',
                pathName:'Corporate Scorecard'
            }
        ]
    }
]


export default modules