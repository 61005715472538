import React from 'react';
import { ApiTable as Table, ColumnDef} from 'components/tables';
import { Button } from 'components/buttons';
import { useDonwloadScoreCardMutation } from 'lib/redux/api/scheduler.slice';

interface ScoreCardTableProps {
    
}

type columnType = {
    job_id: string;
    fk_scheduler_id: string;
    job_status: string;
    error_info: string;
    file_path: string;
    file_name: string;
    created_at: string;
    uptead_at: string;
}

const ScoreCardTable: React.FC<ScoreCardTableProps> = () => {
    const [download, {isLoading}] = useDonwloadScoreCardMutation();
    
    const columns: ColumnDef<columnType>[] = [
        {
            header:'File Name',
            accessorKey: 'file_name',
            cell: props => {
                const value = props.getValue() as string;
                return <strong>{value}</strong>
            }
        },
        {
            header:'Job Id',
            accessorKey: 'job_id'
        },
        {
            header:'Scheduler Id',
            accessorKey: 'fk_scheduler_id'
        },
        {
            header:'Status',
            accessorKey:'job_status'
        },
        {
            header: 'Error Info',
            accessorKey: 'error_info'
        },
        {
            header:'Created Date',
            accessorKey: 'created_at'
        },
        {
            header:'Action',
            cell: props => {
                const handleDownload = async() => {
                    await download({
                        filePath: props.row.original.file_path
                    })
                }
                
                return <Button disabled={props.row.original.job_status !== 'completed'} isLoading={isLoading} onClick={handleDownload} size='xs' color='primary'>Download</Button>
            }
        }
    ]
    
    return <>
        <Table
            columns={columns}
            route='/v1/scheduler/scorecard'
        />
    </>;
}

export default ScoreCardTable