import React from 'react'
import modules from 'data/modules';
import DrawerItems from './DrawerItems';
import { Transition } from '@headlessui/react';

interface DrawerContentProps {

}

const DrawerContent: React.FC<DrawerContentProps> = () => { 
    return <div className='h-full'>
        <ul>
            {
                modules.map(module => (
                    
                    <DrawerItems
                        key={module.header_id}
                        header={module.header}
                        childrens={module.childrens}
                    />
                    
                ))
            }
        </ul>
    </div>;
}

export default DrawerContent