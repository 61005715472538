import React from 'react';
import { ApiTable as Table, ColumnDef} from 'components/tables';
import { Button } from 'components/buttons';
import {useDisclosure} from 'hooks';
import ManualModal from '../modals/ManualModal';
import EditModal from '../modals/EditModal';
import {ModalContext} from '../../context/Context';
import type {modalContextType} from '../../context/Context';
import moment from 'moment';

interface SchedulerTableProps {
    
}

type columnType = {
    scheduler_id:string;
    is_active: number;
    sched_description: string;
    start_time_label: string;
    start_time_cron: string;
    redis_config_key: string;
}


const SchedulerTable: React.FC<SchedulerTableProps> = () => {
    const manual = useDisclosure()
    const edit = useDisclosure()
    const [selected,setSelected] = React.useState<modalContextType>({
        start_time_label: null, 
        start_time_cron: '',
        is_active: false,
        scheduler_id: null
    })
    
    const column:ColumnDef<columnType,any>[] = [
        {
            header:'scheduler_id',
            accessorKey:'scheduler_id',
            cell(props) {
                const handleSelect = () => {
                    const row = props.row.original
                    setSelected({
                        scheduler_id: row.scheduler_id,
                        start_time_label:row.start_time_label?  new Date(row.start_time_label) : null,
                        start_time_cron: row.start_time_cron,
                        is_active: row.is_active === 1
                    })
                    edit.onOpen()
                }
                
                return <Button color='ghost' size='xs'><span className='font-semibold' onClick={handleSelect}>{props.getValue()}</span></Button>
            },
        },
        {
            header:'Status',
            accessorKey:'is_active',
            cell(props) {
                return props.getValue() === 1 ? 'Active' : 'Inactive'
            },
        },
        {
            header:'Description',
            accessorKey:'sched_description'
        },
        {
            header:'Start Time',
            accessorKey:'start_time_label',
            cell(props) {
                return props.getValue() ?  moment(props.getValue()).format('hh:mm A') : null
            }
        },
        {
            header:'Redis Key',
            accessorKey:'redis_config_key'
        },
        {
            header:'Action',
            cell(props) {
                return <div className='flex gap-2'>
                    <Button size='xs' color='primary'>Logs</Button>
                    <Button size='xs' color='primary' onClick={manual.onOpen}>Manual</Button>
                </div>
            },
        }
    ]

    return <>
        <Table columns={column} route='/v1/scheduler'/>
        <ModalContext.Provider value={selected}>
            <ManualModal    isOpen={manual.isOpen}  onClose={manual.onClose}/>
            <EditModal      isOpen={edit.isOpen}    onClose={edit.onClose}/>
        </ModalContext.Provider>
    </>;
}

export default SchedulerTable;