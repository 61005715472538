import React, { ReactNode } from 'react'

interface HeaderProps {
    title:string;
    children?: ReactNode
}

const Header: React.FC<HeaderProps> = (props) => {
    return <div className='flex prose w-full h-16 items-center'>
        <h2>{props.title}</h2>
        {props.children}
    </div>;
}

export default Header