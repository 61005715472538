import React from 'react'

interface ContainerProps {
    children?: React.ReactNode
}

const Container: React.FC<ContainerProps> = ({children}) => {
    return <div className='p-5 border bg-base-100 rounded-md'>
        {children}
    </div>
}

export default Container