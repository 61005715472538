import { configureStore,combineReducers } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import {apiSlice,rtkQueryErrorLogger} from './api/api.slice';
import authSlice from './slices/auth.slice';
import appSlice from './slices/app.slice';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

const reducers = combineReducers({
    [apiSlice.reducerPath]:apiSlice.reducer,
    app: appSlice,
    auth: persistReducer(persistConfig, authSlice),
})


export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
        .concat(apiSlice.middleware,rtkQueryErrorLogger),
    devTools: process.env.NODE_ENV !== 'production',
})


export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch