import React from 'react';
import DatePicker from 'react-datepicker';
import {sizes} from 'components/types';
import {createPortal} from 'react-dom';
import "react-datepicker/dist/react-datepicker.css";

interface TimeInputProps {
    label?:     string;
    size?:      sizes;
    disabled?:  boolean;
    onChange:   (date: Date | [Date | null, Date | null] | null, event: React.SyntheticEvent<any, Event> | undefined) => void;
    value:      Date | undefined | null;   
}

const sizeList = {
    normal: '',
    xs: 'input-xs',
    sm: 'input-sm',
    md: 'input-md',
    lg: 'input-lg',  
}

const PopperContainer: React.FC<{children: React.ReactNode}> = (props) =>{
    const el:HTMLElement = document.getElementById('root')!
    return createPortal(props.children,el)
}

const TimeInput: React.FC<TimeInputProps> = ({size='normal',...props}) => {
    return <>
        <label className='label'>
            <span className='label-text'>
                {props.label}
            </span>
        </label>
        <DatePicker
            showTimeSelect
            showTimeSelectOnly
            disabled={props.disabled}
            className={`input input-bordered w-full ${sizeList[size]}`}
            popperClassName={'!z-50'}
            selected={props.value}
            onChange={props.onChange}
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            popperContainer={
                PopperContainer
            }
        />
    </>;
}

export default TimeInput