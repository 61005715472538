import React from 'react'
import Drawer from './drawer/Drawer'
// import Sidebar from './sidebar/Sidebar'

interface MainProps {
    children: React.ReactNode
}

const Main: React.FC<MainProps> = (props) => {
    return <div className='w-full h-screen' data-theme='bumblebee' >
        {props.children}
          
        {/* <div className='drawer'>
            <input id="drawer-1" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content flex flex-col">
                {props.children}
            </div> 
            <Sidebar/>
        </div> */}
        <Drawer/>
    </div>
}

export default Main