import {GiHamburgerMenu} from 'react-icons/gi'
import React from 'react'
import {AccountMenu} from 'components/menu'
import {Button} from 'components/buttons';
import {toggleDrawer} from 'lib/redux/slices/app.slice';
import {useAppDispatch} from 'hooks';

interface HeaderProps {
    
}

const Navbar: React.FC<HeaderProps> = () => {
    const dispatch = useAppDispatch();

    return <div className='w-full fixed flex h-16 items-center pl-5 pr-5 backdrop-blur-md drop-shadow-lg z-10'> 
        <span className='flex-1'>
            <Button color='ghost' square onClick={() => dispatch(toggleDrawer())}>
                <GiHamburgerMenu/>
            </Button>
        </span>
        <AccountMenu/>
    </div>   
}

export default Navbar