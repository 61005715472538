import React from 'react'

interface NotFoundPageProps {

}

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
    return <div data-theme='light' className='w-full h-screen flex justify-center'>
        NotFoundPage
    </div>;
}

export default NotFoundPage