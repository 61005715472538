import React from 'react';
import {Content,Navbar,Main} from 'components/layouts';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks';
import {getAccessToken} from 'lib/redux/slices/auth.slice'
// import { Components } from 'features/components';
 
interface AppProps {

}

const App: React.FC<AppProps> = () => {
  const token = useAppSelector(getAccessToken);

  if(!token) {
    return <Navigate to='/login' replace/>
  }


  return <Main>
    <Navbar/>
      <Content>
        <Outlet/>
      </Content>
    </Main> 
}

export default App