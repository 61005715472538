import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button } from 'components/buttons';
import { MdClose } from 'react-icons/md';

interface ModalProps {
    title?: string;
    isOpen: boolean;
    onClose?:any;  
    children?: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({title,children,isOpen,onClose}) => {
    return (
        <Transition appear show={isOpen} as={React.Fragment}>
             <Dialog as="div" data-theme={'bumblebee'} className="relative z-10" onClose={()=>{}}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                <div className='flex px-6 py-4 border-b items-center'>
                                    <div className='flex-1'>
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">{title}</Dialog.Title>
                                    </div>
                                    <Button color='ghost' circle size='sm' onClick={onClose}><MdClose/></Button>
                                </div>
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
   
}

export default Modal