import React from 'react'
import ForgotPasswordForm from '../components/forms/ForgotPasswordForm';

interface ForgotPasswordProps {

}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
    return <>
        <div className='w-full h-screen flex justify-center'>
            <div className='relative w-[35rem] h-auto items-center justify-center px-10 py-10 mt-40'>
                <article className='flex flex-col items-center justify-start h-20'>
                    <div className='prose'>
                        <h2>Forgot Password</h2>
                    </div>
                    <p className='not-prose'>Kerry Logistikus Boiler Plate</p>
                </article>
                <ForgotPasswordForm/>
            </div>
        </div>
    </>;
}

export default ForgotPassword