import React from 'react'
import Modal,{Body,Actions} from 'components/modals'
import { Button } from 'components/buttons';
import {TimeInput, ToggleInput} from 'components/input';
import {ModalContext} from '../../context/Context';
import type {modalContextType} from '../../context/Context';
import moment from 'moment';
import { useUpdateSchedulerMutation } from 'lib/redux/api/scheduler.slice';
import { toast } from 'react-toastify';

interface EditModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const EditModal: React.FC<EditModalProps> = (props) => {
    const [update, {isLoading}] = useUpdateSchedulerMutation();
    const modalContext = React.useContext(ModalContext)
    const [state,setState] = React.useState<modalContextType>({
        start_time_label: null, 
        start_time_cron: '',
        is_active: false,
        scheduler_id: null
    })

    const handleTimeChange = (time: any) => {
        const timeToCron = (date: Date) => {
            return `${moment(date).format('mm')} ${moment(date).format('HH')} * * *`
        }

        setState({
            ...state,
            start_time_label: time,
            start_time_cron: timeToCron(time)
        })
    }

    const handleConfirm = async() => {
        await update({
            route: state.scheduler_id,
            body:{
                start_time_label: state.start_time_label, 
                start_time_cron: state.start_time_cron,
                is_active: state.is_active,
            }
        })
        .unwrap()
        .then(() => {
            toast.success('Success')
        })
    }

    React.useEffect(()   => {
        setState(modalContext)
    },[modalContext])

    return (
        <Modal title='Edit Scheduler' isOpen={props.isOpen} onClose={props.onClose}>
            <Body>
                <label>
                    <span className=' font-semibold'>Scheduler ID: </span>
                    <span>{modalContext.scheduler_id}</span>
                </label>
                <TimeInput   label='Time'   value={state.start_time_label}  onChange={handleTimeChange} />
                <ToggleInput label='Status' checked={state.is_active}       onChange={(e) => setState({...state,is_active: e.target.checked})}/>
            </Body>
            <Actions>
                <Button size='sm' color='primary' isLoading={isLoading} onClick={handleConfirm}>Confirm</Button>
            </Actions>
        </Modal>
    )
}

export default EditModal