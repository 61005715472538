import React from 'react'
import {Menu} from '@headlessui/react';

interface MenuItemProps {
    children: React.ReactNode
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
    return (
        <Menu.Item>
            <div className='flex flex-wrap w-full'>
                {props.children}
            </div>
        </Menu.Item>
    )
}

export default MenuItem