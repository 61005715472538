import React from 'react';
import {colors, sizes} from 'components/types';

const colorList = {
    'primary':  'btn-primary',
    'secondary':'btn-secondary',
    'accent' :  'btn-accent',
    'ghost':    'btn-ghost',
    'link' :    'btn-link',
    'info' :    'btn-info',
    'success':  'btn-success',
    'warning':  'btn-warning',
    'error':    'btn-error',
    'normal':''
}

const sizeList = {
    normal: '',
    xs: 'btn-xs',
    sm: 'btn-sm',
    md: 'btn-md',
    lg: 'btn-lg',       
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
    color?:         colors ;
    size?:          sizes;
    square?:        boolean;
    circle?:        boolean;
    block?:         boolean;
    wide?:          boolean;
    isLoading?:     boolean;
    isDisabled?:    boolean;
    outlined?:      boolean;
}

const Button: React.FC<ButtonProps> = ({color='normal',size='normal',isLoading,square,circle,block,isDisabled,className,outlined,...props}) => {
    return <button className={`btn 
        ${colorList[color]} 
        ${sizeList[size]}
        ${square ? 'btn-square' : ''}
        ${circle ? 'btn-circle' : ''}
        ${block ? 'btn-block' : ''}
        ${isLoading ? 'loading' : ''}
        ${isDisabled ? 'btn-disabled' : ''}
        ${outlined ? 'btn-outline' : ''}
        ${className}`
        } {...props}>

        {isLoading ? <></> :  props.children}
    </button>;
}

export default Button