import React from 'react'
import LoginForm from '../components/forms/LoginForm';
import kli from 'assets/kli_logo.png';
import {Navigate} from 'react-router-dom';
import {useAppSelector} from 'hooks';
import {getAccessToken} from 'lib/redux/slices/auth.slice';

interface LoginProps {
    
}

const Login: React.FC<LoginProps> = () => {
    const token = useAppSelector(getAccessToken);
   
    if(token){
        return <Navigate to='/' replace/>
    }

    return <div data-theme='business' className='w-full h-screen flex justify-center bg-[#3C3C3E]'>
        <div className='relative w-[35rem] h-auto items-center justify-center px-10 py-10 mt-40'>
            <div className='flex flex-col items-center justify-start h-30 gap-3'>
                <div className='flex-1'>
                    <img className=' max-w-sm' src={kli} alt='kli_logo'/>
                </div>
                <p className='not-prose'>Kerry Logistikus | Data Warehouse</p>
            </div>
            <LoginForm/>
        </div>
    </div>;
}

export default Login