import React from 'react'

const useDisclosure = () => {
    const [isOpen,setOpen] = React.useState(false);

    const onOpen = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const onToggle=() => {
        setOpen(!isOpen)
    }

    return {isOpen, onOpen, onClose, onToggle} as const
}

export default useDisclosure