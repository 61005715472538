import {apiSlice} from './api.slice';

const tableApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPaginated: builder.query<any,{
            route: string;
            limit?: number;
            page?: number;
            filters?:{};
        }>({
            query:({route,...props}) => ({
                url: route,
                params: {
                    ...props
                }
            }),
            providesTags:['Pagination']
        })
    })
})

export const {
    useGetPaginatedQuery
} = tableApiSlice;