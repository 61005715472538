import {apiSlice} from './api.slice';

const authApiSLice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation<any,{body:{email: string,password: string}}>({
            query:({body}) => ({
                url: 'v1/auth/login',
                method:'POST',
                body:{
                    ...body
                }
            })
        }),
        logut: builder.mutation<any,{}>({
            query: () => ({
                url: 'v1/auth/logout',
                method: 'POST'
            })
        })
    })
})


export const {useLoginMutation, useLogutMutation} = authApiSLice;