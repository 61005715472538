import React from 'react'

interface DrawerFooterProps {

}

const DrawerFooter: React.FC<DrawerFooterProps> = () => {
    return <div className='h-1 p-2  flex items-baseline font-light'>
        <span>v.1.0.0</span>
    </div>;
}

export default DrawerFooter