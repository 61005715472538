import {apiSlice} from './api.slice';
import {saveAs} from 'file-saver'

type defaultType = {
    route: string | null,
}

const schedulerApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        updateScheduler: builder.mutation<any, defaultType & {
            body:{
                start_time_label: Date | null;
                start_time_cron: string | null;
                is_active: boolean;
            },
        }>({
            query:(args) => ({
                url: `v1/scheduler/scheduler-id/${args.route}`,
                method:'PUT',
                body:args.body
            }),
            invalidatesTags:['Pagination']
        }),
        donwloadScoreCard: builder.mutation<any, {
            filePath: string;
        }> ({
            query: (args) => ({
                url: `v1/scheduler/scorecard`,
                method:'POST',
                body:args,
                responseHandler:(res) => res.blob(),
                cache: 'no-cache'
            }),
            transformResponse: (res: any,meta) => {
                saveAs(res,'score_card.xlsx')
            }

        })
    })
})

export const {
    useUpdateSchedulerMutation,
    useDonwloadScoreCardMutation
} = schedulerApiSlice