import React,{Fragment} from 'react';
import {getDrawer,toggleDrawer} from 'lib/redux/slices/app.slice';
import {useAppSelector,useAppDispatch} from 'hooks';
import { Dialog, Transition } from '@headlessui/react';
// import {MdClose} from 'react-icons/md';
// import { Button } from 'components/buttons';
import kli_logo from 'assets/kli_logo.png';
interface DrawerContainerProps {
    children: React.ReactNode
}

const DrawerContainer: React.FC<DrawerContainerProps> = (props) => {
    const dispatch = useAppDispatch()
    const isOpen = useAppSelector(getDrawer);
    const setOpen = () => {
        dispatch(toggleDrawer())
    }
    return (<div>
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className='fixed inset-0 overflow-hidden'>
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-720 sm:duration-720"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-720 sm:duration-720"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-sm">
                                    <div className='flex h-full flex-col overflow-y-scroll py-6 shadow-xl bg-[#282829]'>
                                        <div className="flex px-4 sm:px-6">
                                            <Dialog.Title className="flex flex-col w-full justify-center px-16 py-10">
                                                <img src={kli_logo} alt='kli_logo' className=''/>
                                            </Dialog.Title>
                                        </div>
                                        <div className="relative mt-6 flex-1 flex flex-col justify-between">
                                            {props.children}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </div>);
}

export default DrawerContainer