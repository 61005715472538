import React from 'react';
import {sizes} from 'components/types';

interface ToggleInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>,'size'> {
    label?:string;
    size?: sizes;
    disabled?: boolean;
}

const sizeList = {
    normal: '',
    xs: 'toggle-xs',
    sm: 'toggle-sm',
    md: 'toggle-md',
    lg: 'toggle-lg',  
}

const ToggleInput: React.FC<ToggleInputProps> = ({label,size='normal',...props}) => {
    return <>
        <label className='label'>
            <span className='label-text'>
                {label}
            </span>   
        </label>
        <input type={'checkbox'} className={`toggle ${sizeList[size]} toggle-primary`} {...props}/>
    </>;
}

export default ToggleInput